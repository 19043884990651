<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import { Colli, ColliScanEventType } from '@/types/delivery-management'
import { colliScanEventTypes } from '@/utils/delivery-management'
import { datetime } from '@/utils/dates'

export interface Props {
    colli: Colli
}

defineProps<Props>()

const { t } = useI18n()
</script>

<template>
    <div class="flex flex-col w-full py-2 px-3">
        <div class="flex items-center">
            <div class="flex w-1/3 flex-col">
                <span class="text-sm font-semibold" v-text="colli.colliNumber" />
                <span class="text-xs" v-text="colli.name" />

                <div
                    v-if="colli.minimumTemperature || colli.maximumTemperature"
                    class="flex space-x-1"
                >
                    <span class="text-xs">{{ colli.minimumTemperature ?? '-' }}</span>
                    <span class="text-xs">/</span>
                    <span class="text-xs">{{ colli.maximumTemperature ?? '-' }}°C</span>
                </div>
            </div>

            <div class="flex w-1/3 flex-col justify-center mr-1">
                <div class="flex self-center">
                    <span class="text-xs text-primary-300" v-text="t('dimensions')" />
                    <span class="ml-1 text-xs text-primary-300" v-text="t('lxwxh')" />
                </div>
                <span class="self-center">
                    {{ colli.length }} x {{ colli.width }} x {{ colli.height }} {{ t('cm') }}
                </span>
            </div>

            <div class="flex w-2/6 space-x-4">
                <div class="flex flex-col text-center">
                    <span class="text-xs text-primary-300" v-text="t('volume')" />
                    <span v-text="colli.volume + ' ' + t('cbm')" />
                </div>
                <div class="flex flex-col text-center">
                    <span class="text-xs text-primary-300" v-text="t('weight')" />
                    <span v-text="colli.weight + ' ' + t('kg')" />
                </div>
            </div>

            <div class="flex w-1/8 justify-center space-x-4">
                <div class="flex flex-col justify-center text-center">
                    <span class="text-xs text-primary-300" v-text="t('amount')" />
                    <span v-text="colli.amount" />
                </div>
            </div>

            <div class="flex flex-col w-1/6 space-y-1.5 justify-center flex-wrap">
                <span class="text-xs text-primary-300 text-center" v-text="t('events')" />
                <div
                    v-if="colli.scanEvents.length > 0"
                    class="flex justify-center space-x-1 flex-wrap"
                >
                    <div
                        v-for="event in colli.scanEvents"
                        :key="event.id"
                        v-tooltip="
                            t(colliScanEventTypes[event.type]) + '\n' + datetime(event.createdAt)
                        "
                    >
                        <mdi:human-dolly v-if="event.type === ColliScanEventType.Load" />
                        <mdi:human-dolly
                            v-else-if="event.type === ColliScanEventType.Unload"
                            class="rotate-y-180"
                        />
                        <mdi:truck v-else />
                    </div>
                </div>
                <span v-else class="text-xs text-center">-</span>
            </div>
        </div>
    </div>
</template>
